import React from "react"
import "../assets/index.css"

export default () => (
    <body className="style-guide">
        <div className="container">
            <div className="row">
                <div className="column">
                    <h1>Ladd Partners Web - Style Guide</h1>

                    <h3>01 Typography</h3>
                    <p>Below you can specify the projects typography. Include a preview, font-family, font-size, font-weight, line-height and the colours. Color will vary based off the design specifications</p>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <h1 className="white">h1. Headline </h1>
                    <p>Font-family: Monserrat Semi Bold <br />
                    Font-weight: 600 <br />
                    Font-size: 48px <br />
                    Line-height:  54px <br />
                    Color: rgb(255, 255, 255) #FFFFFF
                    </p>
                </div>

                <div className="column">
                    <h2>h2. SubHeadline</h2>
                    <p>Font: Monserrat Semi Bold<br />
                    Font-weight:  600<br />
                    Font-size: 32px<br />
                    Line-height:  38px<br />
                    Color: rgb(106, 124, 146) #6A7C92
                    </p>
                </div>

                <div className="column">
                    <h3>h3. Subtitle - 20/26</h3>
                    <p>Font: Monserrat Semi Bold<br />
                    Font-weight: 600<br />
                    Font-size: 20px<br />
                    Line-height:  26px<br />
                    Color: rgb(106, 124, 146) #6A7C92
                    </p>
                </div>

            </div>
            <div className="row">
                <div className="column">
                    <h4>h4. Label - 18/26</h4>
                    <p>Font: Monserrat Semi Bold<br />
                    Font-weight:  600<br />
                    Font-size: 18px<br />
                    Line-height:  26px<br />
                    Color: rgb(106, 124, 146) #6A7C92
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <p>p - 16/24</p>
                    <p>Font: Monserrat Regular<br />
                    Font-weight: 400<br />
                    Font-size: 16px<br />
                    Line-height:  24px<br />
                    Color: rgb(106, 124, 146) #6A7C92
                </p>
                </div>

                <div className="column">
                    <p className="hero">p.hero - 18/30</p>
                    <p>Font: Monserrat Medium<br />
                    Font-weight: 500<br />
                    Font-size: 18px<br />
                    Line-height:  30px<br />
                    Color: rgb(106, 124, 146) #6A7C92
                </p>
                </div>

                <div className="column">
                    <p className="intro">p.intro - 18/30</p>
                    <p>Font: Monserrat Regular<br />
                    Font-weight: 400<br />
                    Font-size: 18px<br />
                    Line-height:  30px<br />
                    Color: rgb(106, 124, 146) #6A7C92
                </p>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <p><a href="/">Hyperlink Blue</a></p>
                    <p>Font: Monserrat Medium<br />
                    Font-weight: 500<br />
                    Font-size: 16px<br />
                    Line-height:  24px<br />
                    Color: rgb (195, 86, 96) #23C0F5
                </p>
                </div>
            </div>

            <div className="row">
                <div className="column">
                    <h3>02 Color Palette</h3>
                </div>
            </div>

            <div className="row">
                <div className="column">
                    <div className="square bg-text-grey"></div>
                    <p>Hex #6A7C92 <br/>
                       rgb (106, 124, 146)</p>
                </div>
                <div className="column">
                    <div className="square bg-dark-blue"></div>
                    <p>Hex #182F5A <br/>
                    rgb (219, 73, 35)</p>
                </div>
                <div className="column">
                    <div className="square bg-blue"></div>
                    <p>Hex #3D7BB2 <br/>
                       rgb (61, 123, 178)</p>
                </div>
                <div className="column">
                    <div className="square bg-light-blue"></div>
                    <p>Hex #009EDB<br/>
                    rgb (0, 158, 219)</p>
                </div>
                <div className="column">
                    <div className="square bg-dark-green"></div>
                    <p>Hex #33BBA1 <br/>
                    rgb (51, 187, 161)</p>
                </div>
                <div className="column">
                    <div className="square bg-green"></div>
                    <p>Hex #40EBCA<br/>
                    rgb (64, 235, 202)</p>
                </div>
            </div>

            <div className="row">
                <div className="column">
                    <div className="square bg-gradient-home"></div>
                    <p>Home</p>
                </div>
                <div className="column">
                    <div className="square bg-gradient-ff"></div>
                    <p>FinancialForce</p>
                </div>
                <div className="column">
                    <div className="square bg-gradient-sf"></div>
                    <p>Salesforce</p>
                </div>
                <div className="column">
                    <div className="square bg-gradient-general"></div>
                    <p>General 1</p>
                </div>
                <div className="column">
                    <div className="square bg-gradient-general2"></div>
                    <p>General 2</p>
                </div>
            </div>

            <div className="row">
                <div className="column">
                    <button> Default </button>
                </div>
                <div className="column">
                    <button className="secondary"> Secondary </button>
                </div>
            </div>
        </div>
    </body>
)
